import 'swiper/css'
import "swiper/css/effect-fade"
import "swiper/css/lazy"
import "swiper/css/pagination"
import "swiper/css/navigation"

import "plyr/dist/plyr.css"
//import "plyr-react/plyr.css"

import './src/styles/global.css'
import './src/styles/photoswipe.css'


//export const registerServiceWorker = () => true

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}
